$(function() {
    $('form').find('input[name=company-type]').click(function() {
        if ($(this).val() == 'private') {
            $('#company-box').slideUp();
        } else {
            $('#company-box').slideDown();
        }
    });

    $('.delete-basket-item').click(function(e) {
        e.preventDefault();
        $.get($(this).data('uri') + '?id=' + $(this).data('id'), function() {
            location.reload();
        });
    });

    $('#set-promo-code').click(function(e) {
        e.preventDefault();
        document.location.href = $(this).data('uri') + '?code=' + $('#promo-code').val() + '#promo';
    });

    $('#promo-code').bind('keypress', function(event) {
        if(event.which == 13) {
            event.preventDefault();
            document.location.href = $('#set-promo-code').data('uri') + '?code=' + $('#promo-code').val() + '#promo';
        }
    });

    $('.edit-quantity-basket-item').change(function() {
        document.location.href=$(this).data('uri') + '?id=' + $(this).data('id') + '&quantity=' + $(this).find('option:selected').val();
    });

    $('.price-switcher').change(function() {
        var option = $(this).find('option:selected');
        var standard_price = option.data('standard-price');
        var price = option.data('price');
        var link = $('.dyn-link');

        var split = price.split(',');
        var p_cents = split[1];
        var p_precents = split[0];

        $('.dyn-standard-price').html('€ ' + standard_price + '/maand');
        $('.dyn-price').html(p_precents + ',<span class="price-cents">' + p_cents + '</span>');
        $('.dyn-ppm').html(option.data('ppm'));
        link.attr('href', link.data('uri') + '?product=' + link.data('product') + '&period=' + option.data('period'))
    });

    function renderValidationHtml(errors) {

        var html = '<div class="alert alert-danger" role="alert">';
        html = html + '<strong>Graag onderstaande zaken eerst corrigeren:</strong>'
        html = html + '<ul>';
        $.each(errors, function(k, v) {
            html = html + '<li>' + v + '</li>';
        });
        html = html + '</ul>';
        html = html + '</div>';

        return html;
    }


    $('body').on('submit', 'form.js-submit', function(e) {
        e.preventDefault();

        var $form = $(this);

        $.post($(this).attr('action'), $(this).serializeArray())
            .done(function(data) {
                document.location.href = data.redirect;
            })
            .error(function(errors) {

                $form.find('.feedback').html(
                    renderValidationHtml(errors.responseJSON)
                );

            });
    });
});