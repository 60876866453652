var MapsModule = (function() {

    var map = null;
    var mapsContainer = null;
    var latLng = null;

    var init = function (mapsContainerId) {
        mapsContainer = mapsContainerId;

        latLng = new google.maps.LatLng(51.0734059, 2.595628);
        
        google.maps.event.addDomListener(window, 'load', buildMap);

        $(window).resize(function(){
            map.setCenter(latLng);
        });
    };
    
    var buildMap = function() {
        var mapOptions = {
            zoom: 10,
            center: latLng,
            scrollwheel: false,
            mapTypeControl: false,
            streetViewControl: false
        };

        map = new google.maps.Map(document.getElementById(mapsContainer), mapOptions);

        var marker = new google.maps.Marker({
            position: latLng,
            map: map,
            title: 'Schuifdamhoeve - Bij boer Bart'
        });

        var infowindow = new google.maps.InfoWindow({
            content: '<p class="marker"><strong>Online Tobacco</strong><br />Duinkerkekeiweg 5<br />8660 Adinkerke</p><p><a href="https://www.google.be/maps/place/Duinkerkekeiweg+5,8660+Adinkerke/" target="_blank">Open in Google Maps</a></p>'
        });

        google.maps.event.addListener(marker, 'click', function() {
            infowindow.open(map, marker);
        });
        google.maps.event.addDomListener(map, 'idle', function(){
            center = map.getCenter();
        });
    };
    
    return {
        init: init
    }
})();